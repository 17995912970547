import React, { Fragment } from 'react'
import { Certificate, ShieldCheck, UserSwitch } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import BackgroundMission1 from 'src/assets/our-company/bg-mission-1.png'
import BackgroundMission2 from 'src/assets/our-company/bg-mission-2.png'
import BackgroundMission3 from 'src/assets/our-company/bg-mission-3.png'
import BackgroundMission4 from 'src/assets/our-company/bg-mission-4.png'
import BackgroundMission5 from 'src/assets/our-company/bg-mission-5.png'
import BackgroundMission6 from 'src/assets/our-company/bg-mission-6.png'
import BackgroundStory from 'src/assets/our-company/bg-story.png'

import Button from 'src/components/core/button'

export default function OurCompany() {
    return (
        <Fragment>
            <div className='h-screen relative overflow-hidden w-full'>
                <div className='flex h-full items-center max-w-[1366px] mx-auto px-6 w-full'>
                    <div className='flex flex-col gap-6 max-w-[730px] pt-32'>
                        <h1 className='font-black text-white'>Accelerating Climate Actions Through <span className='text-secondary'>Comprehensive Carbon Solutions</span></h1>
                        <h6 className='font-semibold text-white'>We are a mission-driven B2B carbon management company, committed to help your organization navigate through a decarbonization journey and turning it into real climate actions with our end-to-end carbon solutions.</h6>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-8 w-fit' variant='fill'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
                <div className='absolute bg-gradient-to-t from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='flex flex-col gap-12 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6 max-w-[800px] mx-auto w-full'>
                    <h1 className='font-bold text-center'>Our Mission</h1>
                    <p className='body1 text-center'>Empower your business with truly impactful and scientific climate solutions.</p>
                </div>
                <div className='flex flex-col gap-3'>
                    <div className='flex gap-3'>
                        <div className='flex-1 overflow-hidden h-[300px]'>
                            <img src={BackgroundMission1} alt="Mission 1" className='h-full object-cover w-full' height={300} width={600} />
                        </div>
                        <div className='h-[300px] overflow-hidden w-[300px]'>
                            <img src={BackgroundMission2} alt="Mission 3" className='h-full object-cover w-full' height={300} width={300} />
                        </div>
                        <div className='h-[300px] overflow-hidden w-[300px]'>
                            <img src={BackgroundMission3} alt="Mission 3" className='h-full object-cover w-full' height={300} width={300} />
                        </div>
                    </div>
                    <div className='flex gap-3'>
                        <div className='h-[300px] overflow-hidden w-[300px]'>
                            <img src={BackgroundMission4} alt="Mission 4" className='h-full object-cover w-full' height={300} width={600} />
                        </div>
                        <div className='h-[300px] overflow-hidden w-[300px]'>
                            <img src={BackgroundMission5} alt="Mission 5" className='h-full object-cover w-full' height={300} width={300} />
                        </div>
                        <div className='flex-1 overflow-hidden h-[300px]'>
                            <img src={BackgroundMission6} alt="Mission 6" className='h-full object-cover w-full' height={300} width={300} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full container'>
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>Our Story</h1>
                    <p className='body1'>
                        TruCarbon was founded in 2022 with a clear mission: to accelerate meaningful climate action across Southeast Asia through scalable carbon management solutions driven by science, technology, and finance. Recognizing the urgent need for greater progress in the region, TruCarbon was established to address the gap between climate goals and tangible impact. Our team brings together deep expertise in carbon accounting, soil science, forestry, and renewable energy, working collaboratively to deliver high-quality, science-based solutions.
                        <br /><br />
                        With a commitment to driving real, measurable climate impact, TruCarbon leverages its expertise in both the technical and commercial aspects of carbon science and policy. Our goal is to support businesses in navigating their climate journey, ensuring that together, we can create a more sustainable future.
                    </p>
                </div>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
            </div>
            {/*<div className='flex flex-col gap-12 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6 max-w-[800px] mx-auto w-full'>
                    <h1 className='font-bold text-center'>Featured In</h1>
                    <p className='body1 text-center'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
                <div className='flex gap-12 items-stretch'>
                    <div className='flex gap-6 items-center'>
                        <div className='flex items-center justify-center p-6 rounded-full shadow-[0px_0px_12px_#00000025]'>
                            <Certificate color='#2E89BC' size={48} weight='fill' />
                        </div>
                        <div className='flex items-center justify-center px-8 py-6 shadow-[0px_0px_12px_#00000025]'>
                            <p className='body1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                    <div className='flex gap-6 items-center'>
                        <div className='flex items-center justify-center p-6 rounded-full shadow-[0px_0px_12px_#00000025]'>
                            <UserSwitch color='#62B4AD' size={48} weight='fill' />
                        </div>
                        <div className='flex items-center justify-center px-8 py-6 shadow-[0px_0px_12px_#00000025]'>
                            <p className='body1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                </div>
                <div className='flex gap-12 items-stretch'>
                    <div className='flex gap-6 items-center'>
                        <div className='flex items-center justify-center p-6 rounded-full shadow-[0px_0px_12px_#00000025]'>
                            <ShieldCheck color='#FADF70' size={48} weight='fill' />
                        </div>
                        <div className='flex items-center justify-center px-8 py-6 shadow-[0px_0px_12px_#00000025]'>
                            <p className='body1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                    <div className='flex gap-6 items-center'>
                        <div className='flex items-center justify-center p-6 rounded-full shadow-[0px_0px_12px_#00000025]'>
                            <Certificate color='#E17E7F' size={48} weight='fill' />
                        </div>
                        <div className='flex items-center justify-center px-8 py-6 shadow-[0px_0px_12px_#00000025]'>
                            <p className='body1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className='flex flex-col gap-3 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='px-32 py-16 shadow-[0px_0px_12px_#00000025] quote-on-mobile'>
                    <p className='body1 italic'>
                        "At TruCarbon, our team is committed to transforming climate action into mutually beneficial solutions. We recognize that climate change is not solely an environmental challenge but one that demands practical, real-world approaches."
                    </p>
                    <p className='body1 text-end'>- Debby Reynata</p>
                </div>
            </div>
            <div className='flex flex-col gap-12 h-full max-w-[800px] mx-auto px-6 py-32 w-full'>
                <h2 className='font-bold text-center'>TruCarbon Introduces New Platform to<br/>Accelerate Enterprise Decarbonization</h2>
                <p className='body1 text-center'>
                    The TruCarbon Platform combines the company's deep expertise in climate science, policy, and carbon markets with SaaS-based end-to-end carbon management, providing actionable climate strategy and comprehensive tracking and reporting.
                </p>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        {/*<h4 className='text-center'>Start your climate journey with us.</h4>*/}
                        <h1 className='font-bold text-center'>
                            Start your climate journey with us.
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

import React from 'react'
import { CaretRight, FacebookLogo, InstagramLogo, LinkedinLogo, WhatsappLogo } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import Logo from 'src/assets/TruCarbon Logo-05.png'

import Button from './core/button'

export default function Footer() {

    const listAboutUs = [
        {
            id: 0,
            href: '/our-company',
            label: 'Our Company',
        },
        {
            id: 1,
            href: '/expertise',
            label: 'Our Expertise',
        },
        /*{
            id: 2,
            href: '/our-team',
            label: 'Our Team',
        },
        {
            id: 3,
            href: '/our-client',
            label: 'Our Clients',
        },
        {
            id: 4,
            href: '/our-partner',
            label: 'Our Partners',
        },*/
    ]

    const listSolutions = [
        {
            id: 0,
            href: '/solutions',
            label: 'Measure',
        },
        {
            id: 1,
            href: '/solutions',
            label: 'Reduce',
        },
        {
            id: 2,
            href: '/solutions',
            label: 'Offset',
        },
        {
            id: 3,
            href: '/research',
            label: 'Report',
        },
    ]

    const listServices = [
        {
            id: 0,
            href: '/',
            label: 'Climate Strategy',
        },
        {
            id: 1,
            href: '/',
            label: 'Bespoke Procurement',
        },
        {
            id: 2,
            href: '/',
            label: 'Carbon Removal Portofollios',
        },
        {
            id: 3,
            href: '/',
            label: 'Climate Policy',
        },
        {
            id: 4,
            href: '/',
            label: 'Climate Justice',
        },
        {
            id: 5,
            href: '/',
            label: 'Sustainable Aviation Fuel',
        },
        {
            id: 6,
            href: '/',
            label: 'Inesetting',
        },
        {
            id: 7,
            href: '/',
            label: 'Custom Services',
        },
    ]

    return (
        <div className='bg-primary-dark'>
            <div className='flex flex-col gap-3 max-w-[1366px] mx-auto pt-24 px-12'>
                <div className='flex gap-8 items-start'>
                    <div className='flex flex-1 flex-col gap-8'>
                        <img src={Logo} alt="Logo" height={48} width={263} />
                        <div className='flex gap-6'>
                            {/*<LinkedinLogo color='#FFFFFF' size={40} />
                            <InstagramLogo color='#FFFFFF' size={40} />
                            <FacebookLogo color='#FFFFFF' size={40} />
                            <WhatsappLogo color='#FFFFFF' size={40} />*/}
                        </div>
                        {/*<div className='flex flex-col gap-6'>
                            <Link to={'/contact-us'}>
                                <Button classNameAdditional='bg-white w-fit' variant='fill'>Get Started</Button>
                            </Link>
                            <Link to={'/purchase-carbon'}>
                                <Button classNameAdditional='text-white w-fit' variant='bordered'>Purchase Carbon Credits</Button>
                            </Link>
                        </div>*/}
                    </div>
                    <div className='flex flex-1 flex-col gap-12'>
                        <h4 className='font-semibold text-white'>About Us</h4>
                        <div className='flex flex-col gap-3'>
                            {listAboutUs.map(item => {
                                return (
                                    <div key={item?.id} className='flex gap-2 items-center'>
                                        <CaretRight color='#FFFFFF' size={20} weight='fill' />
                                        <Link to={item?.href} className='text-white'>
                                            {item?.label}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/*<div className='flex flex-1 flex-col gap-12'>
                        <h4 className='font-semibold text-white'>Solutions</h4>
                        <div className='flex flex-col gap-3'>
                            {listSolutions.map(item => {
                                return (
                                    <div key={item?.id} className='flex gap-2 items-center'>
                                        <CaretRight color='#FFFFFF' size={20} weight='fill' />
                                        <Link to={item?.href} className='text-white'>
                                            {item?.label}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='flex flex-1 flex-col gap-12'>
                        <h4 className='font-semibold text-white'>Services</h4>
                        <div className='flex flex-col gap-3'>
                            {listServices.map(item => {
                                return (
                                    <div key={item?.id} className='flex gap-2 items-center'>
                                        <CaretRight color='#FFFFFF' size={20} weight='fill' />
                                        <Link to={item?.href} className='text-white'>
                                            {item?.label}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>*/}
                </div>
                {/*<div className='flex gap-6 items-stretch py-6'>
                    <div className='flex flex-1 flex-col gap-6'>
                        <div className='flex flex-col gap-4'>
                            <h6 className='font-bold text-white'>Investment</h6>
                            <p className='body1 text-white'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        </div>
                        <Link to={'/purchase-carbon'}>
                            <Button classNameAdditional='mt-auto text-white w-fit' variant='bordered'>Purchase Carbon Credits</Button>
                        </Link>
                    </div>
                    <div className='bg-white w-[1px]'></div>
                    <div className='flex flex-1 flex-col gap-6'>
                        <div className='flex flex-col gap-4'>
                            <h6 className='font-bold text-white'>Apply</h6>
                            <p className='body1 text-white'>Applying for grant for your impactful projects? We can help!</p>
                        </div>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-auto w-fit' variant='fill'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
                <hr />*/}
                <div className='flex justify-between gap-6 py-8'>
                    <p className='body1 font-semibold text-white'>© 2024. All rights reserved.</p>
                    {/*<div className='flex gap-12'>
                        <p className='body1 font-semibold text-white'>Privacy Policy</p>
                        <p className='body1 font-semibold text-white'>Terms & Conditions</p>
                    </div>*/}
                </div>
            </div>
        </div>
    )
}

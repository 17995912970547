import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { CaretDown, List } from '@phosphor-icons/react'

import BackgroundStory from 'src/assets/our-company/bg-story.png'
import Logo from 'src/assets/TruCarbon Logo-05.png'

import Button from './core/button';

export default function Navbar() {

    const [getPathname, setPathname] = useState('');

    const [getShowMenu, setShowMenu] = useState('');

    useEffect(() => {
        window.addEventListener('resize', CloseMenu);

        setPathname(window.location.pathname);

        return () => {
            window.removeEventListener('resize', CloseMenu);
        }
    }, []);

    useEffect(() => {
        CloseMenu();
    }, [getPathname]);

    const CloseMenu = () => {
        setShowMenu(false);
    }

    return (
        <nav className='bg-black backdrop-blur-md fixed overflow-visible shadow-md top-0 w-full z-[3] xl:bg-black/70'>
            <div className='flex gap-10 items-center justify-between max-w-[1366px] mx-auto overflow-visible px-6 py-5 relative w-full'>
                <div className='flex gap-12 items-center'>
                    <Link to={'/'}>
                        <img src={Logo} alt="Logo" className='h-10 object-contain w-fit' height={40} width={205} />
                    </Link>
                    <div className='hidden items-center relative xl:flex'>
                        <Link to={'/'} className={`font-semibold group px-8 relative text-white `}>
                            Why TruCarbon
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/our-company'} className={`font-semibold group px-8 relative text-white `}>
                            Our Company
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/expertise'} className={`font-semibold group px-8 relative text-white `}>
                            Our Expertise
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        {/*<Link to={'/our-team'} className={`font-semibold group px-8 relative text-white `}>
                            Our Team
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/our-client'} className={`font-semibold group px-8 relative text-white `}>
                            Our Clients
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/our-partner'} className={`font-semibold group px-8 relative text-white `}>
                            Our Partners
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>*/}
                        {/*<div className='group/container relative'>
                            <button className={`flex font-semibold gap-2 group items-center px-8 relative text-white`}>
                                Solutions
                                <CaretDown color='#ffffff' size={16} weight='fill' />
                                <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                            </button>
                            <div className='absolute hidden pt-10 top-full w-[640px] group-hover/container:block'>
                                <div className='bg-black/80 border-[1px] border-white/30 flex flex-col gap-3 relative rounded-2xl p-6'>
                                    <div className='flex flex-col gap-2'>
                                        <p className='caption2 font-bold text-white'>About Us</p>
                                        <div className='grid grid-cols-2 gap-x-1 gap-y-2'>
                                            <Link to={'/our-company'}>
                                                <p className='caption1 text-white'>Our Company</p>
                                            </Link>
                                            <Link to={'/our-client'}>
                                                <p className='caption1 text-white'>Our Clients</p>
                                            </Link>
                                            <Link to={'/expertise'}>
                                                <p className='caption1 text-white'>Our Expertise</p>
                                            </Link>
                                            <Link to={'/our-partner'}>
                                                <p className='caption1 text-white'>Our Partners</p>
                                            </Link>
                                            <Link to={'/our-team'}>
                                                <p className='caption1 text-white'>Our Team</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <hr />
                                    <p className='caption2 font-bold text-white'>Feature Insight</p>
                                    <div className='flex gap-4 items-center'>
                                        <div className='flex flex-col gap-2 w-[400px]'>
                                            <p className='caption1 font-bold text-white'>Blog</p>
                                            <p className='caption1 text-justify text-white'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                                        </div>
                                        <div className='overflow-hidden'>
                                            <img src={BackgroundStory} alt='Blog' className='object-cover' width={240} />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='flex gap-3'>
                                        <Link to={'/contact-us'}>
                                            <p className='caption1 font-bold text-white'>Contact us</p>
                                        </Link>
                                        <div className='bg-[#D9D9D9] w-[1px]'></div>
                                        <Link to={'/purchase-carbon'}>
                                            <p className='caption1 font-bold text-white'>Purchase carbon credit</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to={'/technology'} className={`font-semibold group px-8 relative text-white `}>
                            Technology
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <div className='group/container relative'>
                            <button className={`flex font-semibold gap-2 group items-center px-8 relative text-white`}>
                                Resources
                                <CaretDown color='#ffffff' size={16} weight='fill' />
                                <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                            </button>
                            <div className='absolute hidden pt-10 top-full w-[640px] group-hover/container:block'>
                                <div className='bg-black/80 border-[1px] border-white/30 flex gap-3 relative rounded-2xl'>
                                    <div className='border-r flex flex-col gap-2'>
                                        <div className='grid gap-4 w-[280px] p-6'>
                                            <p className='caption2 font-bold text-white'>About Us</p>
                                            <Link to={'/blog'}>
                                                <p className='caption2 font-bold text-white'>Blog</p>
                                                <p className='caption1 text-white'>It is a long established fact</p>
                                            </Link>
                                            <Link to={'/research'}>
                                                <p className='caption2 font-bold text-white'>Research and reports</p>
                                                <p className='caption1 text-white'>It is a long established fact that a reader </p>
                                            </Link>
                                            <Link to={'/webinars'}>
                                                <p className='caption2 font-bold text-white'>Webinars</p>
                                                <p className='caption1 text-white'>It is a long established fact</p>
                                            </Link>
                                        </div>
                                        <div className='border-t flex gap-3 px-6 py-4'>
                                            <Link to={'/contact-us'}>
                                                <p className='caption1 font-bold text-white'>Contact us</p>
                                            </Link>
                                            <div className='bg-[#D9D9D9] w-[1px]'></div>
                                            <Link to={'/purchase-carbon'}>
                                                <p className='caption1 font-bold text-white'>Purchase carbon credit</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-4 p-4'>
                                        <p className='caption2 font-bold text-white'>Feature Insight</p>
                                        <div className='overflow-hidden'>
                                            <img src={BackgroundStory} alt='Blog' className='object-cover' />
                                        </div>
                                        <div>
                                            <p className='caption2 font-bold text-white'>About Us</p>
                                            <p className='caption1 text-white'>It is a long established fact that a reader </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                {/*<div className='hidden gap-6 items-center xl:flex'>
                    <Button classNameAdditional='border-white px-8 py-2 text-white w-fit' variant='bordered'>Login</Button>
                    <Button classNameAdditional='bg-white px-8 py-2 text-primary-dark w-fit' variant='fill'>Get Started</Button>
                </div>*/}
                <button type='button' className='relative xl:hidden' onClick={() => setShowMenu(prevState => !prevState)}>
                    <List color='#659864' size={28} weight='regular' />
                </button>
                {getShowMenu ?
                    <div className='absolute bg-black border-t flex flex-col h-[calc(100vh-86px)] left-0 overflow-auto py-4 shadow-xl top-full w-full z-[2]'>
                        <Link to={'/'} className={`font-semibold group px-8 py-3 relative text-white hover:text-third`}>
                            Why TruCarbon
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/our-company'} className={`font-semibold group px-8 py-3 relative text-white hover:text-third`}>
                            Our Company
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <Link to={'/expertise'} className={`font-semibold group px-8 py-3 relative text-white hover:text-third`}>
                            Our Expertise
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        {/*<div className='group/container relative'>
                            <button className={`flex font-semibold gap-2 group items-center justify-between px-8 py-3 relative text-white w-full hover:text-third`}>
                                Solutions
                                <CaretDown color='#ffffff' size={16} className='group-hover/container:rotate-180' weight='fill' />
                            </button>
                            <div className='hidden group-hover/container:block'>
                                <div className='flex-col gap-3 relative px-12'>
                                    <Link to={'/our-company'}>
                                        <p className='caption1 py-2 text-white hover:text-third'>Our Company</p>
                                    </Link>
                                    <Link to={'/our-client'}>
                                        <p className='caption1 py-2 text-white hover:text-third'>Our Clients</p>
                                    </Link>
                                    <Link to={'/expertise'}>
                                        <p className='caption1 py-2 text-white hover:text-third'>Our Expertise</p>
                                    </Link>
                                    <Link to={'/our-partner'}>
                                        <p className='caption1 py-2 text-white hover:text-third'>Our Partners</p>
                                    </Link>
                                    <Link to={'/our-team'}>
                                        <p className='caption1 py-2 text-white hover:text-third'>Our Team</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Link to={'/technology'} className={`font-semibold group px-8 py-3 relative text-white hover:text-third`}>
                            Technology
                            <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all`}></div>
                        </Link>
                        <div className='group/container relative'>
                            <button className={`flex font-semibold gap-2 group items-center justify-between px-8 py-3 relative text-white w-full hover:text-third`}>
                                Resources
                                <CaretDown color='#ffffff' size={16} className='group-hover/container:rotate-180' weight='fill' />
                            </button>
                            <div className='hidden group-hover/container:block'>
                                <div className='flex-col gap-3 relative px-12'>
                                    <Link to={'/blog'}>
                                        <div className='py-2 text-white hover:text-third'>
                                            <p className='caption2 font-bold'>Blog</p>
                                            <p className='caption1'>It is a long established fact</p>
                                        </div>
                                    </Link>
                                    <Link to={'/research'}>
                                        <div className='py-2 text-white hover:text-third'>
                                            <p className='caption2 font-bold'>Research and reports</p>
                                            <p className='caption1'>It is a long established fact that a reader </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    : null
                }
            </div>
        </nav>
    )
}

import React, { Fragment, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Favicon from 'src/assets/TruCarbon Logo-07.png'

import Footer from '../components/footer'
import Navbar from 'src/components/navbar'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Layout() {

    const { pathname, search } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, search]);

    return (
        <Fragment>
            <link rel="icon" type="image/x-icon" href={Favicon}></link>
            <Navbar />
            <Outlet />
            <Footer />
        </Fragment>
    )
}

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import BackgroundBanner from 'src/assets/home/bg-banner.png'

import Button from 'src/components/core/button'

export default function Blog() {
    return (
        <Fragment>
            <div className='h-screen relative overflow-hidden w-full'>
                <div className='flex h-full items-center max-w-[1366px] mx-auto px-6 w-full'>
                    <div className='flex flex-col gap-6 max-w-[731px] pt-32'>
                        <h1 className='font-black text-white'>BLOG</h1>
                        <h6 className='font-semibold text-white'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</h6>
                        <Button classNameAdditional='bg-white mt-8 w-fit' variant='fill' onClick={() => document.getElementById('blog').scrollIntoView({ behavior: 'smooth' })}>Explore Now</Button>
                    </div>
                </div>
                <div className='absolute bg-gradient-to-t from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div id='blog' className='flex flex-col gap-10 max-w-[1366px] mx-auto px-6 py-24'>
                <div className='flex flex-col gap-6'>
                    <h4 className='font-bold'>Hightlight</h4>
                    <div className='gap-6 grid grid-cols-3'>
                        {Array.from(Array(3).keys()).map(item => {
                            return (
                                <Link to={'/blog/1'} key={item} className='overflow-hidden relative rounded-3xl shadow-[0px_0px_122px_#00000015]'>
                                    <div className='h-[240px] overflow-hidden w-full'>
                                        <img src={BackgroundBanner} alt='Blog' className='h-full object-cover w-full' />
                                    </div>
                                    <div className='flex flex-col gap-3 p-3'>
                                        <div className='flex flex-wrap gap-2 items-stretch'>
                                            <p className='caption2 text-dark-light'>09 September 2024</p>
                                            <div className='bg-dark-light w-[1px]'></div>
                                            <div className='bg-primary-main/10 border border-primary-main px-4 py-[1px] rounded-full text-[10px] text-primary-main'>
                                                Carbon Reduction
                                            </div>
                                            <div className='bg-danger/10 border border-danger px-4 py-[1px] rounded-full text-[10px] text-danger'>
                                                Climate News
                                            </div>
                                        </div>
                                        <h6 className='font-bold'>Solution Built to Accelerate</h6>
                                        <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages...</p>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className='flex flex-col gap-6'>
                    <h4 className='font-bold'>All Blog</h4>
                    <div className='gap-6 grid grid-cols-3'>
                        {Array.from(Array(30).keys()).map(item => {
                            return (
                                <Link to={'/blog/1'} key={item} className='overflow-hidden relative rounded-3xl shadow-[0px_0px_122px_#00000015]'>
                                    <div className='h-[240px] overflow-hidden w-full'>
                                        <img src={BackgroundBanner} alt='Blog' className='h-full object-cover w-full' />
                                    </div>
                                    <div className='flex flex-col gap-3 p-3'>
                                        <div className='flex flex-wrap gap-2 items-stretch'>
                                            <p className='caption2 text-dark-light'>09 September 2024</p>
                                            <div className='bg-dark-light w-[1px]'></div>
                                            <div className='bg-primary-main/10 border border-primary-main px-4 py-[1px] rounded-full text-[10px] text-primary-main'>
                                                Carbon Reduction
                                            </div>
                                            <div className='bg-danger/10 border border-danger px-4 py-[1px] rounded-full text-[10px] text-danger'>
                                                Climate News
                                            </div>
                                        </div>
                                        <h6 className='font-bold'>Solution Built to Accelerate</h6>
                                        <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages...</p>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        <h4 className='text-center'>What is Lorem Ipsum</h4>
                        <h1 className='font-bold text-center'>
                            What is Lorem Ipsum? Start Now
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

import React from 'react'
import { Envelope, MapPin, Phone } from '@phosphor-icons/react'
import { useFormik } from 'formik';
import * as yup from 'yup';

import BackgroundContactUs from 'src/assets/bg-contact-us.png'
import BackgroundMaps from 'src/assets/bg-maps.png'

import Button from 'src/components/core/button'
import InputText from 'src/components/core/input/input-text'
import InputTextArea from 'src/components/core/input/input-textarea'
import Select from 'src/components/core/select'

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

export default function ContactUs() {
    const formik = useFormik({
        initialValues: {
            email: 'foobar@example.com',
            password: 'foobar',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <div className='relative w-full'>
            <div className='flex gap-16 max-w-[1366px] mx-auto px-6 py-32 w-full container'>
                <div className='flex flex-1 flex-col gap-10'>
                    <iframe className='gmaps' title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.413854496492!2d106.81582427514974!3d-6.209018660820055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f520cf631bfb%3A0x85dc9ea843f3d9d2!2sTruClimate!5e0!3m2!1sen!2sid!4v1730830972254!5m2!1sen!2sid" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    <div className='flex flex-col gap-6'>
                        <div className='flex gap-6'>
                            <div className='flex flex-1 gap-3 items-start'>
                                <MapPin color='#043333' size={32} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body1 font-bold'>Address</p>
                                    <p className='body1'>Citylofts Sudirman Unit 820<br />Jl. K.H. Mas Mansyur No. 121<br />Central Jakarta, Indonesia 10220</p>
                                </div>
                            </div>
                            <div className='flex flex-1 gap-3 items-start'>
                                <Phone color='#043333' size={32} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body1 font-bold'>Phone</p>
                                    <p className='body1'>(+62) 822-2888-6906</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-3 items-start'>
                            <Envelope color='#043333' size={32} />
                            <div className='flex flex-col gap-1'>
                                <p className='body1 font-bold'>Email</p>
                                <p className='body1'>contact@trucarbon.co</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-1 flex-col gap-6'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='flex flex-col gap-2 pb-6'>
                            <h1 className='font-bold'>Get In Touch...</h1>
                            <p className='body1'>Find out how TruCarbon can help you measure, reduce, and offset your company's emissions.</p>
                        </div>
                        <div className='grid grid-cols-2 gap-6'>
                            <InputText
                                label='Company Name'
                                required={true}
                            />
                            <InputText
                                label='Job Role'
                                required={true}
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-6'>
                            <InputText
                                label='First Name'
                                required={true}
                            />
                            <InputText
                                label='Last Name'
                                required={true}
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-6'>
                            <InputText
                                label='Country'
                                required={true}
                            />
                            <InputText
                                label='Work Email Address'
                                required={true}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-6'>
                            <InputText
                                label='Phone Number'
                                required={true}
                            />
                            <InputText
                                label='Subject'
                            />
                        </div>
                        <InputTextArea
                            label='Message'
                            rows={5}
                        />
                        {/*<div className='grid grid-cols-2 gap-6'>
                            <Select
                                label='How did you hear about us?'
                                placeholder='Select...'
                            />
                            <Select
                                label='How do you prefer to be contacted?'
                                placeholder='Select...'
                            />
                        </div>*/}
                        <Button classNameAdditional='bg-primary-dark text-white w-fit' variant='fill' type='submit'>Submit</Button>
                    </form>
                </div>
            </div>
            <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                <img src={BackgroundContactUs} alt='Contact Us' className='h-full object-cover' />
            </div>
        </div>
    )
}

import { ArrowRight } from '@phosphor-icons/react'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import BackgroundBanner from 'src/assets/home/bg-banner.png'

import Button from 'src/components/core/button'

export default function Research() {
    return (
        <Fragment>
            <div className='flex flex-col gap-10 max-w-[1366px] mx-auto px-6 pb-24 pt-40'>
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>Resarch and Report</h1>
                    <div className='gap-6 grid grid-cols-3'>
                        {Array.from(Array(30).keys()).map(item => {
                            return (
                                <Link to={'/research/1'} key={item} className='overflow-hidden relative rounded-3xl shadow-[0px_0px_122px_#00000015]'>
                                    <div className='h-[240px] overflow-hidden w-full'>
                                        <img src={BackgroundBanner} alt='Blog' className='h-full object-cover w-full' />
                                    </div>
                                    <div className='flex flex-col gap-3 p-3'>
                                        <div className='bg-primary-main px-4 py-1 rounded-full text-sm text-white w-fit'>
                                            Report
                                        </div>
                                        <h6 className='font-bold'>Solution Built to Accelerate</h6>
                                        <div className='flex gap-4 items-center justify-between'>
                                            <p className='caption2 text-dark-light'>09 September 2024</p>
                                            <button type='button' className='border-[2px] border-primary-main flex h-10 items-center justify-center rounded-full w-10'>
                                                <ArrowRight color='#0A564B' size={16} />
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        <h4 className='text-center'>What is Lorem Ipsum</h4>
                        <h1 className='font-bold text-center'>
                            What is Lorem Ipsum? Start Now
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

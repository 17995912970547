import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import ImageOrganize from 'src/assets/expertise/img-organize.png'
import ImageSection1 from 'src/assets/expertise/img-section-1.png'

import Button from 'src/components/core/button'

export default function Expertise() {
    return (
        <Fragment>
            <div className='h-screen relative overflow-hidden w-full'>
                <div className='flex h-full items-center max-w-[1366px] mx-auto px-6 w-full'>
                    <div className='flex flex-col gap-6 max-w-[730px] pt-32'>
                        <h1 className='font-black text-white'>At TruCarbon, We Believe That <span className='text-info'>Climate Change</span> is The Most Critical Challenge of Our Era</h1>
                        <h6 className='font-semibold text-white'>The actions that businesses, organizations, and governments take in cutting and eliminating emissions over the next 25 years will affect billions of people. And we need to do the impactful actions, as soon as possible, as many as possible.</h6>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-8 w-fit' variant='fill'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
                <div className='absolute bg-gradient-to-t from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            {/*<div className='flex flex-col gap-16 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <img src={ImageOrganize} alt="Organize" className='object-contain' height={610} width={1318} />
            </div>*/}
            <div className='flex flex-col gap-16 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <h1 className='font-bold mx-auto text-center'>Hence We Bring<br/>Multidisciplinary Expertise to Climate Action</h1>
                <div className='flex flex-col gap-32'>
                    <div className='flex gap-12 h-full items-center w-full container'>
                        <img src={ImageSection1} alt="Story" className='object-cover' height={373} width={659} />
                        <div className='flex flex-col gap-6'>
                            <h2 className='font-bold text-secondary'>Sourcing</h2>
                            <p className='body1'>
                                Climate science encompasses a wide range of disciplines, all collaborating toward a shared objective: ensuring the long-term well-being of our planet and its people.
                                <br /><br />
                                At TruCarbon, we unite experts from various areas of climate science, including carbon accounting, forest management, industrial decarbonization, life cycle assessment, and geospatial analysis. Our team also collaborates with experts in business, carbon markets, and policy to provide tailored carbon management solutions designed to address your organization's specific requirements.
                            </p>
                        </div>
                    </div>
                    <div className='flex gap-12 h-full items-center w-full container'>
                        <div className='flex flex-col gap-6'>
                            <h2 className='font-bold text-secondary'>Diligence</h2>
                            <p className='body1'>
                                At TruCarbon, we believe that climate change is the most critical challenge of our era. The actions that businesses, organizations, and governments take in cutting and eliminating emissions over the next 25 years will affect billions of people.
                                <br /><br />
                                We are dedicated to providing all stakeholders with the technical and business expertise necessary to make bold, effective climate decisions that pave the way for a more sustainable future for our planet.
                            </p>
                        </div>
                        <img src={ImageSection1} alt="Story" className='object-cover' height={373} width={659} />
                    </div>
                </div>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        {/*<h4 className='text-center'>What is Lorem Ipsum</h4>*/}
                        <h1 className='font-bold text-center'>
                            Start your climate journey with us.
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import Blog from './pages/blog';
import BlogDetail from './pages/blog/detail';
import BySolutions from './pages/by-solutions';
import ContactUs from './pages/contact-us';
import DetailClient from './pages/our-client/detail';
import Expertise from './pages/expertise';
import Home from './pages/home';
import Layout from './pages/layout';
import OurClient from './pages/our-client';
import OurCompany from './pages/our-company';
import OurTeam from './pages/our-team';
import OurPartner from './pages/our-partner';
import PurchaseCarbon from './pages/purchase-carbon';
import Research from './pages/research';
import ResearchDetail from './pages/research/detail';
import Technology from './pages/technology';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/:id' element={<BlogDetail />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/expertise' element={<Expertise />} />
                    <Route path='/our-client' element={<OurClient />} />
                    <Route path='/our-client/:id' element={<DetailClient />} />
                    <Route path='/our-company' element={<OurCompany />} />
                    <Route path='/our-partner' element={<OurPartner />} />
                    <Route path='/our-team' element={<OurTeam />} />
                    <Route path='/purchase-carbon' element={<PurchaseCarbon />} />
                    <Route path='/research' element={<Research />} />
                    <Route path='/research/:id' element={<ResearchDetail />} />
                    <Route path='/solutions' element={<BySolutions />} />
                    <Route path='/technology' element={<Technology />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();

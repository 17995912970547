import React from 'react'
import { Link } from 'react-router-dom'

import BackgroundContactUs from 'src/assets/bg-contact-us.png'

import InputText from 'src/components/core/input/input-text'
import Button from 'src/components/core/button'

export default function ResearchDetail() {
    return (
        <div className='flex min-h-screen relative w-full'>
            <div className='flex gap-16 items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-1 flex-col gap-10'>
                    <div>
                        <h4 className='font-bold text-secondary'>A buyer's guide</h4>
                        <h1 className='font-bold'>Enhanced Rock Weathering in Croplands</h1>
                    </div>
                    <p className='body1'>Enhanced rock weathering may offer durable, cost-competitive carbon removal with potential added benefits like increased crop yields. However, uncertainty persists in ERW projects, with key risks related to over crediting and the potential for causing environmental and social harms. This means that, to maximize benefits and reduce risks, this carbon removal pathway requires more research and investment. </p>
                </div>
                <div className='flex flex-1 flex-col gap-6'>
                    <div className='grid grid-cols-2 gap-6'>
                        <InputText
                            label='First Name'
                            required={true}
                        />
                        <InputText
                            label='Last Name'
                            required={true}
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-6'>
                        <InputText
                            label='Company Name'
                            required={true}
                        />
                        <InputText
                            label='Job Role'
                            required={true}
                        />
                    </div>
                    <InputText
                        label='Work Email Address'
                        required={true}
                    />
                    <p className='caption2 italic'>By continuing, you are agreeing to the Carbon Direct Terms of Service, Privacy Policy. You will also join our mailing list for marketing updates and other carbon management news.</p>
                    <Link to={'/reports'}>
                        <Button classNameAdditional='bg-primary-dark text-white w-fit' variant='fill'>Get the report</Button>
                    </Link>
                </div>
            </div>
            <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                <img src={BackgroundContactUs} alt='Contact Us' className='h-full object-cover' />
            </div>
        </div>
    )
}

import React, { Fragment } from 'react'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import IconClient3 from 'src/assets/our-client/ic-client-3.svg'
import IconProfile from 'src/assets/our-client/ic-profile.svg'

import Button from 'src/components/core/button'

import { Circle } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

export default function OurClient() {
    return (
        <Fragment>
            <div className='h-screen relative w-full'>
                <div className='flex flex-col gap-6 h-full items-center justify-center max-w-[800px] mx-auto px-6 w-full'>
                    <h1 className='font-black text-white'>Lorem Ipsum</h1>
                    <h6 className='text-center text-white'>Measure, reduce, and offset your carbon emissions with TruCarbon end-to-end carbon management solutions</h6>
                </div>
                <div className='absolute bg-gradient-to-r from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='flex flex-col gap-16 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6 items-center'>
                    <h1 className='font-bold text-center'>Clients Who Trust Us</h1>
                    <p className='body1 text-center w-[600px]'>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    </p>
                </div>
                <div className='gap-12 grid grid-cols-4 justify-evenly'>
                    <Link to={'/our-client/1'} className='duration-300 group relative shadow-[0px_0px_12px_#00000015] transition-transform hover:scale-105'>
                        <div className='flex h-[156px] items-center justify-center mx-auto p-4'>
                            <img src={IconClient3} alt="Client 1" className='duration-300 grayscale h-full object-contain transition-all group-hover:grayscale-0' />
                        </div>
                        <div className='p-4'>
                            <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </Link>
                    <Link to={'/our-client/1'} className='duration-300 group relative shadow-[0px_0px_12px_#00000015] transition-transform hover:scale-105'>
                        <div className='flex h-[156px] items-center justify-center mx-auto p-4'>
                            <img src={IconClient3} alt="Client 1" className='duration-300 grayscale h-full object-contain transition-all group-hover:grayscale-0' />
                        </div>
                        <div className='p-4'>
                            <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </Link>
                    <Link to={'/our-client/1'} className='duration-300 group relative shadow-[0px_0px_12px_#00000015] transition-transform hover:scale-105'>
                        <div className='flex h-[156px] items-center justify-center mx-auto p-4'>
                            <img src={IconClient3} alt="Client 1" className='duration-300 grayscale h-full object-contain transition-all group-hover:grayscale-0' />
                        </div>
                        <div className='p-4'>
                            <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </Link>
                    <Link to={'/our-client/1'} className='duration-300 group relative shadow-[0px_0px_12px_#00000015] transition-transform hover:scale-105'>
                        <div className='flex h-[156px] items-center justify-center mx-auto p-4'>
                            <img src={IconClient3} alt="Client 1" className='duration-300 grayscale h-full object-contain transition-all group-hover:grayscale-0' />
                        </div>
                        <div className='p-4'>
                            <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='bg-dark/10'>
                <div className='flex flex-col gap-16 mx-auto py-32 w-full'>
                    <h1 className='font-bold text-center'>Lorem Ipsum</h1>
                    <div className='flex gap-6 hide-scrollbar justify-start overflow-auto p-6'>
                        <div className='bg-white border-b-8 border-r-8 border-primary-main flex flex-col gap-3 min-w-[360px] max-w-[360px] p-4 rounded-2xl shadow-[0px_0px_12px_#00000025]'>
                            <div className='flex gap-3 items-center'>
                                <img src={IconProfile} alt="Profile" className='h-16 object-contain rounded-full w-16' height={64} width={64} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body2 font-bold'>Theory Janne</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='body1 text-dark-light'>Theory Janne</p>
                                        <Circle color='#32373C' size={4} weight='fill' />
                                        <p className='body1 text-dark-light'>2024</p>
                                    </div>
                                </div>
                            </div>
                            <p className='body1'>"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."</p>
                        </div>
                        <div className='bg-white border-b-8 border-r-8 border-primary-main flex flex-col gap-3 min-w-[360px] max-w-[360px] p-4 rounded-2xl shadow-[0px_0px_12px_#00000025]'>
                            <div className='flex gap-3 items-center'>
                                <img src={IconProfile} alt="Profile" className='h-16 object-contain rounded-full w-16' height={64} width={64} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body2 font-bold'>Theory Janne</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='body1 text-dark-light'>Theory Janne</p>
                                        <Circle color='#32373C' size={4} weight='fill' />
                                        <p className='body1 text-dark-light'>2024</p>
                                    </div>
                                </div>
                            </div>
                            <p className='body1'>"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."</p>
                        </div>
                        <div className='bg-white border-b-8 border-r-8 border-primary-main flex flex-col gap-3 min-w-[360px] max-w-[360px] p-4 rounded-2xl shadow-[0px_0px_12px_#00000025]'>
                            <div className='flex gap-3 items-center'>
                                <img src={IconProfile} alt="Profile" className='h-16 object-contain rounded-full w-16' height={64} width={64} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body2 font-bold'>Theory Janne</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='body1 text-dark-light'>Theory Janne</p>
                                        <Circle color='#32373C' size={4} weight='fill' />
                                        <p className='body1 text-dark-light'>2024</p>
                                    </div>
                                </div>
                            </div>
                            <p className='body1'>"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."</p>
                        </div>
                        <div className='bg-white border-b-8 border-r-8 border-primary-main flex flex-col gap-3 min-w-[360px] max-w-[360px] p-4 rounded-2xl shadow-[0px_0px_12px_#00000025]'>
                            <div className='flex gap-3 items-center'>
                                <img src={IconProfile} alt="Profile" className='h-16 object-contain rounded-full w-16' height={64} width={64} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body2 font-bold'>Theory Janne</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='body1 text-dark-light'>Theory Janne</p>
                                        <Circle color='#32373C' size={4} weight='fill' />
                                        <p className='body1 text-dark-light'>2024</p>
                                    </div>
                                </div>
                            </div>
                            <p className='body1'>"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."</p>
                        </div>
                        <div className='bg-white border-b-8 border-r-8 border-primary-main flex flex-col gap-3 min-w-[360px] max-w-[360px] p-4 rounded-2xl shadow-[0px_0px_12px_#00000025]'>
                            <div className='flex gap-3 items-center'>
                                <img src={IconProfile} alt="Profile" className='h-16 object-contain rounded-full w-16' height={64} width={64} />
                                <div className='flex flex-col gap-1'>
                                    <p className='body2 font-bold'>Theory Janne</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='body1 text-dark-light'>Theory Janne</p>
                                        <Circle color='#32373C' size={4} weight='fill' />
                                        <p className='body1 text-dark-light'>2024</p>
                                    </div>
                                </div>
                            </div>
                            <p className='body1'>"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        <h4 className='text-center'>What is Lorem Ipsum</h4>
                        <h1 className='font-bold text-center'>
                            What is Lorem Ipsum? Start Now
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

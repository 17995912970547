import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { BuildingOffice, Car, ChartPolar, CurrencyDollar, FlowerLotus, LineSegments, PaperPlane, Truck } from '@phosphor-icons/react'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import BackgroundWhyChoose from 'src/assets/home/bg-why-choose.svg'
import ImageSection1 from 'src/assets/expertise/img-section-1.png'

import Button from 'src/components/core/button'

export default function BySolutions() {
    return (
        <Fragment>
            <div className='h-screen relative w-full'>
                <div className='flex flex-col gap-6 h-full items-center justify-center max-w-[800px] mx-auto px-6 w-full'>
                    <h1 className='font-black text-white'>Lorem Ipsum</h1>
                    <h6 className='text-center text-white'>Measure, reduce, and offset your carbon emissions with TruCarbon end-to-end carbon management solutions</h6>
                </div>
                <div className='absolute bg-gradient-to-r from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='bg-primary-dark'>
                <div className='flex flex-col gap-12 h-full max-w-[1366px] mx-auto py-24 px-6 w-full'>
                    <h1 className='font-bold max-w-[800px] mx-auto text-center text-white'>What's in a footprint?</h1>
                    <p className='body1 max-w-[800px] mx-auto text-center text-white'>
                        Operations. Employees. Partners. Customers. Suppliers. Facilities. All of these (and more) contribute to your carbon footprint. Some of the most common sources of emissions are intuitive. Others are complicated and unique to your operations.
                    </p>
                    <div className='gap-4 grid grid-cols-3'>
                        <div className='flex flex-col gap-2'>
                            <CurrencyDollar color='#FADF70' size={48} />
                            <h3 className='font-bold text-white'>Goods and services</h3>
                            <p className='body1 text-white'>Everything your business spends money on has associated emissions. Goods (like furniture and electronics) and services (like legal and HR vendors) contribute to your business' footprint.</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <PaperPlane color='#FADF70' size={48} />
                            <h3 className='font-bold text-white'>Business travel</h3>
                            <p className='body1 text-white'>In the US, transportation (cars, trucks, commercial aircraft, railroads, and more) accounts for 29% of greenhouse gas emissions.</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <BuildingOffice color='#FADF70' size={48} />
                            <h3 className='font-bold text-white'>Offices</h3>
                            <p className='body1 text-white'>Buildings and offices account for over 40% of global energy usage — and a third of total greenhouse gas emissions.</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Truck color='#FADF70' size={48} />
                            <h3 className='font-bold text-white'>Shipping</h3>
                            <p className='body1 text-white'>Global shipping accounts for 3% of the world’s carbon emissions. If no action is taken to reduce this, it could account for 10% or more within the next few years.</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <Car color='#FADF70' size={48} />
                            <h3 className='font-bold text-white'>Commuting</h3>
                            <p className='body1 text-white'>Transportation is the single largest source of emissions in the US, and work commuting accounts for about 30% of all miles driven in the states.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-12 h-full max-w-[800px] mx-auto px-6 py-32 w-full'>
                <h1 className='font-bold text-center'>Scalable for any organization</h1>
                <p className='body1 text-center'>When it comes to a carbon footprint, there is no one-size-fits-all. Our experts have the knowledge and experience to measure even the most complex emissions profiles.</p>
            </div>
            <div className='flex flex-col gap-16 max-w-[1366px] mx-auto px-6 pb-32 w-full'>
                <div className='flex flex-col gap-32'>
                    <div className='flex gap-12 h-full items-center w-full'>
                        <img src={ImageSection1} alt="Story" className='object-cover' height={373} width={659} />
                        <div className='flex flex-col gap-6'>
                            <h14>Activate your climate strategy</h14>
                            <p className='body1'>
                                We combine machine and human intelligence to give you a clear estimate of your footprint so you know what to do next. Our platform and APIs gather all the right inputs to give you a high-quality output. Every footprint is reviewed by our experts for accuracy and consistency.
                            </p>
                        </div>
                    </div>
                    <div className='flex gap-12 h-full items-center w-full'>
                        <div className='flex flex-col gap-6'>
                            <h14>Visualize your footprint</h14>
                            <p className='body1'>
                                Easily visualize and share your carbon footprint to drive climate action. Customizable reporting allows you to break down categories and export visuals and to share progress with stakeholders.
                            </p>
                        </div>
                        <img src={ImageSection1} alt="Story" className='object-cover' height={373} width={659} />
                    </div>
                    <div className='flex gap-12 h-full items-center w-full'>
                        <img src={ImageSection1} alt="Story" className='object-cover' height={373} width={659} />
                        <div className='flex flex-col gap-6'>
                            <h14>Take informed climate action</h14>
                            <p className='body1'>
                                Set priorities and take action that works for your business. Custom benchmarking leads to actionable goals. Easy-to-understand reporting keeps everyone–company leaders, employees, your board, and your partners—aligned and engaged.
                            </p>
                        </div>
                    </div>
                </div>4
            </div>
            <div className='relative w-full'>
                <div className='flex flex-col gap-12 h-full max-w-[1366px] mx-auto pb-24 px-6 pt-32 w-full'>
                    <div className='flex gap-6 justify-center mx-auto max-w-[800px]'>
                        <h1 className='font-bold text-center text-white'>Solutions built to accelerate your journey to net zero</h1>
                    </div>
                    <div className='flex flex-1 gap-3 items-stretch'>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <ChartPolar color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Measure</h4>
                                <p className='body1 text-center text-white'>Applying scientific and tech expertise for due diligence. Our analyst team mitigates risk in our project portfolios and helps clients procure lower carbon products.</p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <FlowerLotus color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Reduce</h4>
                                <p className='body1 text-center text-white'>Defining what “good” looks like in the voluntary carbon market (VCM). We review every supplier to ensure that our project portfolios drive real climate impact.
                                </p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <LineSegments color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Remove</h4>
                                <p className='body1 text-center text-white'>Meeting you where you are in your climate journey. Carbon Direct supports end-to-end carbon management, scaling high-quality solutions with your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute bg-black/70 h-full left-0 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundWhyChoose} alt='Why Choose' className='h-full object-cover' />
                </div>
            </div>
            <div className='flex flex-col gap-12 h-full max-w-[800px] mx-auto px-6 py-32 w-full'>
                <h1 className='font-bold text-center'>Curated for impact</h1>
                <p className='body1 text-center'>
                    With our curated portfolios, available exclusively through the Carbon Direct Platform, you can immediately access the highest quality carbon removal credits on the market—already vetted and secured by our experts.
                </p>
                <Link to={'/contact-us'}>
                    <Button classNameAdditional='bg-white border-primary-dark mx-auto w-fit' variant='bordered'>Explore curated portofoolios</Button>
                </Link>
            </div>
            <div className='flex flex-col gap-10 max-w-[1366px] mx-auto px-6 py-24'>
                <div className='flex flex-col gap-6'>
                    <h4 className='font-bold'>Our Hightlight News</h4>
                    <div className='gap-6 grid grid-cols-3'>
                        {Array.from(Array(3).keys()).map(item => {
                            return (
                                <Link to={'/blog/1'} key={item} className='overflow-hidden relative rounded-3xl shadow-[0px_0px_122px_#00000015]'>
                                    <div className='h-[240px] overflow-hidden w-full'>
                                        <img src={BackgroundBanner} alt='Blog' className='h-full object-cover w-full' />
                                    </div>
                                    <div className='flex flex-col gap-3 p-3'>
                                        <div className='flex flex-wrap gap-2 items-stretch'>
                                            <p className='caption2 text-dark-light'>09 September 2024</p>
                                            <div className='bg-dark-light w-[1px]'></div>
                                            <div className='bg-primary-main/10 border border-primary-main px-4 py-[1px] rounded-full text-[10px] text-primary-main'>
                                                Carbon Reduction
                                            </div>
                                            <div className='bg-danger/10 border border-danger px-4 py-[1px] rounded-full text-[10px] text-danger'>
                                                Climate News
                                            </div>
                                        </div>
                                        <h6 className='font-bold'>Solution Built to Accelerate</h6>
                                        <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages...</p>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

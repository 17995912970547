import React, { Fragment } from 'react'
import Slider from 'react-slick'
import { ChartPolar, FlowerLotus, LineSegments } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet"

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import BackgroundWhyChoose from 'src/assets/home/bg-why-choose.svg'
import IconClient3 from 'src/assets/our-client/ic-client-3.svg'
import IconWhatWeDo1 from 'src/assets/home/ic-what-we-do-1.svg'
import IconWhatWeDo2 from 'src/assets/home/ic-what-we-do-2.svg'

import Button from 'src/components/core/button'

export default function Home() {

    const settings = {
        autoplay: true,
        autoplaySpeed: -100,
        centerPadding: "0px",
        cssEase: "linear",
        infinite: true,
        slidesToScroll: 1,
        speed: 2000,
        swipeToSlide: true,
        variableWidth: true,
    };

    return (
        <Fragment>
            <Helmet>
                <title>TruCarbon - Climate Transition Solution</title>
            </Helmet>
            <div className='h-screen relative overflow-hidden w-full'>
                <div className='flex h-full items-center max-w-[1366px] mx-auto px-6 w-full'>
                    <div className='flex flex-col gap-6 max-w-[730px] mx-auto pt-32'>
                        <h1 className='font-black text-white'>Start Your <span className='text-secondary'>Climate Transition Journey</span> Today</h1>
                        <h6 className='font-semibold text-white'>Measure, reduce, and offset your carbon emissions with<br />TruCarbon`s comprehensive carbon management solutions.</h6>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-8 w-fit' variant='fill'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
                <div className='absolute bg-gradient-to-t from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='bg-black rounded-b-[48px] py-12 w-full'>
                {/*<Slider className={'flex items-center gap-20 hide-scrollbar overflow-auto'} {...settings}>
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                    <img src={IconClient3} alt="Logo" height={64} width={110} className='grayscale h-full object-contain brightness-200' />
                </Slider>*/}
            </div>
            <div className='flex flex-col gap-32 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6 max-w-[800px] mx-auto w-full'>
                    <h1 className='font-bold text-center'>What We Do</h1>
                    <p className='body1 text-center'>At TruCarbon, we empower you to take control of your environmental impact.<br/>Our comprehensive carbon management solutions help you measure, reduce, and offset<br/>your carbon emissions effectively and sustainably.</p>
                </div>
                <div className='flex gap-32 items-center w-full container'>
                    <img src={IconWhatWeDo1} alt="Icon 1" height={480} width={480} />
                    <div className='flex flex-col gap-12'>
                        <div className='flex flex-col gap-3'>
                            <h1 className='font-bold'>Advisory & Project Development</h1>
                            <p className='body1'>Partner up with us, to utilize our wealth of climate expertise in science, policy and financing throughout your decarbonization journey and carbon project development.</p>
                        </div>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-primary-dark text-white w-fit' variant='fill'>Get Started</Button>
                        </Link>
                    </div>
                </div>
                <div className='flex gap-32 items-center w-full container'>
                    <div className='flex flex-col gap-12'>
                        <div className='flex flex-col gap-3'>
                            <h1 className='font-bold'>Software & Technology</h1>
                            <p className='body1'>For your carbon emissions management, our platform systemizes carbon measurement and recommends reduction plans. For your carbon projects, our technology digitalizes robust monitoring and reporting for high quality carbon credits.</p>
                        </div>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='border-primary-dark text-primary-dark w-fit' variant='bordered'>Get Started</Button>
                        </Link>
                    </div>
                    <img src={IconWhatWeDo2} alt="Icon 2" height={480} width={480} />
                </div>
            </div>
            <div className='h-[680px] relative w-full'>
                <div className='flex gap-24 h-full max-w-[1366px] mx-auto pb-24 px-6 pt-32 w-full'>
                    <div className='flex flex-col max-w-[360px]'>
                        <div className='flex flex-col gap-6'>
                            <h1 className='font-bold text-white'>Why Choose <i className='font-normal'>TruCarbon</i></h1>
                            <p className='body1 text-white'>World-class expertise with local knowledge and support as your climate transition partner. Our team with wealth of climate expertise in science, technology and policy can advise you and guide you through your whole decarbonization journey.</p>
                        </div>
                        <br/>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-auto w-fit' variant='fill'>Get Started</Button>
                        </Link>
                    </div>
                    <div className='flex flex-1 gap-3 items-stretch hide-on-mobile '>
                        <div className='bg-light/30 flex flex-col gap-12 items-center p-6 rounded-3xl'>
                            <ChartPolar color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Project Assessment & Selection</h4>
                                <p className='body1 text-center text-white'>Setting the standard for excellence in the carbon market. We carefully evaluate each carbon project to ensure our portfolio of carbon offsets delivers genuine positive impact for the planet.
                                </p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col gap-12 items-center p-6 rounded-3xl'>
                            <FlowerLotus color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Robust Technical Analysis</h4>
                                <p className='body1 text-center text-white'>Applying our climate expertise in science, technology and financing to ensure that we design the most robust mitigations and solutions for your climate transition journey.
                                </p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col gap-12 items-center p-6 rounded-3xl'>
                            <LineSegments color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>End-to-end solutions</h4>
                                <p className='body1 text-center text-white'>Supporting you at every stage of your climate journey. TruCarbon provides comprehensive carbon management, delivering scalable, high-quality solutions tailored to your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute bg-black/70 h-full left-0 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundWhyChoose} alt='Why Choose' className='h-full object-cover' />
                </div>
            </div>
        </Fragment>
    )
}

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import ImageTeams1 from 'src/assets/our-teams/ic-teams-1.svg'
import ImageTeams2 from 'src/assets/our-teams/ic-teams-2.svg'
import ImageTeams3 from 'src/assets/our-teams/ic-teams-3.svg'
import ImageTeams4 from 'src/assets/our-teams/ic-teams-4.svg'

import Button from 'src/components/core/button'

export default function OurTeam() {
    return (
        <Fragment>
            <div className='h-screen relative w-full'>
                <div className='flex flex-col gap-6 h-full items-center justify-center max-w-[800px] mx-auto px-6 w-full'>
                    <h1 className='font-black text-center text-white'>From climate commitments to climate actions</h1>
                    <h6 className='text-center text-white'>Measure, reduce, and offset your carbon emissions with TruCarbon end-to-end carbon management solutions</h6>
                </div>
                <div className='absolute bg-gradient-to-r from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='flex flex-col gap-12 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='border-l-[6px] border-r-[6px] border-t-[6px] border-dark-main/30 p-16 relative rounded-xl'>
                    <div className='absolute bg-white -top-10 flex flex-col gap-6 left-12 mx-auto px-6 w-[calc(100%-128px)]'>
                        <h1 className='font-bold'>Our <i className='font-normal'>Team</i></h1>
                        <p className='body1 max-w-[600px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                    <div className='gap-3 grid grid-cols-4 pt-16'>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams1} alt="Team 1" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams2} alt="Team 2" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams3} alt="Team 3" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams4} alt="Team 4" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams1} alt="Team 1" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams2} alt="Team 2" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams3} alt="Team 3" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams4} alt="Team 4" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams1} alt="Team 1" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams2} alt="Team 2" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams3} alt="Team 3" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                        <div className='group overflow-hidden relative rounded-3xl'>
                            <div className='absolute bg-black/60 duration-300 flex items-center justify-center h-full left-0 opacity-0 transition-opacity top-0 w-full group-hover:opacity-100'>
                                <div className='flex flex-col gap-2 relative'>
                                    <h6 className='font-bold text-center text-white'>Profile Name</h6>
                                    <p className='body1 text-center text-white'>CEO | Founder</p>
                                </div>
                            </div>
                            <img src={ImageTeams4} alt="Team 4" className='h-full object-cover rounded-3xl w-full' height={280} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='gap-24 h-full max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='bg-primary-dark/10 flex flex-col gap-6 items-center p-16 rounded-[48px]'>
                    <div>
                        <h4 className='text-center'>What is Lorem Ipsum</h4>
                        <h1 className='font-bold text-center'>
                            What is Lorem Ipsum? Start Now
                        </h1>
                    </div>
                    <Link to={'/contact-us'}>
                        <Button classNameAdditional='bg-primary-dark mt-6 mx-auto text-white w-fit' variant='fill'>Get Started</Button>
                    </Link>
                </div>
            </div>
        </Fragment>
    )
}

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ChartPolar, FlowerLotus, LineSegments } from '@phosphor-icons/react'

import BackgroundBanner from 'src/assets/home/bg-banner.png'
import BackgroundStory from 'src/assets/our-company/bg-story.png'
import BackgroundWhyChoose from 'src/assets/home/bg-why-choose.svg'

import Button from 'src/components/core/button'

export default function Technology() {
    return (
        <Fragment>
            <div className='h-screen relative overflow-hidden w-full'>
                <div className='flex h-full items-center max-w-[1366px] mx-auto px-6 w-full'>
                    <div className='flex flex-col gap-6 max-w-[730px] mx-auto pt-32'>
                        <h1 className='font-black text-white'>Navigate your <span className='text-secondary'>climate transition</span> through our platform</h1>
                        <h6 className='font-semibold text-white'>We provide a one-stop solution for your organization to<br />"achieve sustainability targets and commitments"</h6>
                        <Link to={'/contact-us'}>
                            <Button classNameAdditional='bg-white mt-8 w-fit' variant='fill'>Contact Us</Button>
                        </Link>
                    </div>
                </div>
                <div className='absolute bg-gradient-to-t from-black h-full left-0 to-black/30 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundBanner} alt='Banner' className='h-full object-cover w-full' />
                </div>
            </div>
            <div className='flex flex-col gap-3 max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='px-32 py-16 shadow-[0px_0px_12px_#00000025]'>
                    <p className='body1 italic'>
                        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.""
                    </p>
                    <p className='body1 text-end'>- Lorem Ipsum</p>
                </div>
            </div>
            <div className='flex flex-col gap-12 h-full max-w-[800px] mx-auto px-6 py-32 w-full'>
                <h1 className='font-bold text-center'>One planet. One journey. One platform.</h1>
                <p className='body1 text-center'>
                    The Carbon Direct Platform gives you the high-touch experience of direct, customized support from our team of experts paired with speed and flexibility of technology.
                </p>
                <Link to={'/reports'}>
                    <Button classNameAdditional='bg-white border-primary-dark mx-auto w-fit' variant='bordered'>Download the data sheet</Button>
                </Link>
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>TruCount</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>TruCarbon</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>API Integration</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>Enterprise Customization</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>IoT Integration</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
            </div>
            <div className='flex gap-12 h-full items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <img src={BackgroundStory} alt="Story" className='object-cover' height={400} width={800} />
                <div className='flex flex-col gap-6'>
                    <h1 className='font-bold'>White Label</h1>
                    <p className='body1'>
                        Focus your efforts on taking action, not collecting and reviewing data. We support data from most major business platforms to simplify the process and give you confidence in the data integrity.
                    </p>
                </div>
            </div>
            <div className='relative w-full'>
                <div className='flex flex-col gap-12 h-full max-w-[1366px] mx-auto pb-24 px-6 pt-32 w-full'>
                    <div className='flex gap-6 justify-center mx-auto max-w-[800px]'>
                        <h1 className='font-bold text-center text-white'>Solutions built to accelerate your journey to net zero</h1>
                    </div>
                    <div className='flex flex-1 gap-3 items-stretch'>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <ChartPolar color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Measure</h4>
                                <p className='body1 text-center text-white'>Applying scientific and tech expertise for due diligence. Our analyst team mitigates risk in our project portfolios and helps clients procure lower carbon products.</p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <FlowerLotus color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Reduce</h4>
                                <p className='body1 text-center text-white'>Defining what “good” looks like in the voluntary carbon market (VCM). We review every supplier to ensure that our project portfolios drive real climate impact.
                                </p>
                            </div>
                        </div>
                        <div className='bg-light/30 flex flex-col items-center p-6 rounded-3xl'>
                            <LineSegments color='#D9D9D9' size={96} weight='fill' />
                            <div className='flex flex-col gap-6'>
                                <h4 className='flex items-center justify-center font-bold h-[56px] text-center text-white'>Remove</h4>
                                <p className='body1 text-center text-white'>Meeting you where you are in your climate journey. Carbon Direct supports end-to-end carbon management, scaling high-quality solutions with your business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute bg-black/70 h-full left-0 top-0 w-full z-[-1]'></div>
                <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                    <img src={BackgroundWhyChoose} alt='Why Choose' className='h-full object-cover' />
                </div>
            </div>
            <div className='flex flex-col gap-10 max-w-[1366px] mx-auto px-6 py-24'>
                <div className='flex flex-col gap-6'>
                    <h4 className='font-bold'>Our Hightlight News</h4>
                    <div className='gap-6 grid grid-cols-3'>
                        {Array.from(Array(3).keys()).map(item => {
                            return (
                                <Link to={'/blog/1'} key={item} className='overflow-hidden relative rounded-3xl shadow-[0px_0px_122px_#00000015]'>
                                    <div className='h-[240px] overflow-hidden w-full'>
                                        <img src={BackgroundBanner} alt='Blog' className='h-full object-cover w-full' />
                                    </div>
                                    <div className='flex flex-col gap-3 p-3'>
                                        <div className='flex flex-wrap gap-2 items-stretch'>
                                            <p className='caption2 text-dark-light'>09 September 2024</p>
                                            <div className='bg-dark-light w-[1px]'></div>
                                            <div className='bg-primary-main/10 border border-primary-main px-4 py-[1px] rounded-full text-[10px] text-primary-main'>
                                                Carbon Reduction
                                            </div>
                                            <div className='bg-danger/10 border border-danger px-4 py-[1px] rounded-full text-[10px] text-danger'>
                                                Climate News
                                            </div>
                                        </div>
                                        <h6 className='font-bold'>Solution Built to Accelerate</h6>
                                        <p className='caption2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages...</p>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

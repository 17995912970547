import React from 'react'

import BackgroundContactUs from 'src/assets/bg-contact-us.png'

import InputText from 'src/components/core/input/input-text'
import InputTextArea from 'src/components/core/input/input-textarea'
import Button from 'src/components/core/button'

export default function PurchaseCarbon() {
    return (
        <div className='flex min-h-screen relative w-full'>
            <div className='flex gap-16 items-center max-w-[1366px] mx-auto px-6 py-32 w-full'>
                <div className='flex flex-1 flex-col gap-10'>
                    <h1 className='font-bold'>Get access to purchase carbon credits</h1>
                    <p className='body1'>Get access to the Carbon Direct Platform to purchase from our curated portfolios of high-quality carbon removal credits. The Platform includes details from our experts on the benefits of each project in our portfolios.</p>
                </div>
                <div className='flex flex-1 flex-col gap-6'>
                    <div className='grid grid-cols-2 gap-6'>
                        <InputText
                            label='First Name'
                            required={true}
                        />
                        <InputText
                            label='Last Name'
                            required={true}
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-6'>
                        <InputText
                            label='Company Name'
                            required={true}
                        />
                        <InputText
                            label='Job Role'
                            required={true}
                        />
                    </div>
                    <div className='grid grid-cols-2 gap-6'>
                        <InputText
                            label='Country'
                            required={true}
                        />
                        <InputText
                            label='Work Email Address'
                            required={true}
                        />
                    </div>
                    <InputTextArea
                        label='What we can help you?'
                        rows={3}
                    />
                    <p className='caption2 italic'>By continuing, you are agreeing to the Carbon Direct Terms of Service, Privacy Policy. You will also join our mailing list for marketing updates and other carbon management news.</p>
                    <Button classNameAdditional='bg-primary-dark text-white w-fit' variant='fill'>Submit</Button>
                </div>
            </div>
            <div className='absolute left-0 top-0 h-full w-full z-[-2]'>
                <img src={BackgroundContactUs} alt='Contact Us' className='h-full object-cover' />
            </div>
        </div>
    )
}
